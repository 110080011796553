import React from 'react';
import { useAboutPageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import Hero from '@c/Hero';
import ArticleContent from '@c/ArticleContent';

const AboutPage = () => {
  const aboutPageCmsEntry = useAboutPageEntry(),
    aboutPageEntry = aboutPageCmsEntry.entry,
    seomatic = aboutPageCmsEntry.seomatic;

  return (
    <EntryContextProvider entry={aboutPageEntry}>
      <Layout seomatic={seomatic}>
        {!!aboutPageEntry.hero && !!aboutPageEntry.hero.length && (
          <Hero {...aboutPageEntry.hero[0]} />
        )}

        <ArticleContent
          content={aboutPageEntry.articleContent}
          pt={{ xs: 19.5, md: 32 }}
          pb={{ xs: 12.5, md: 22 }}
        />
      </Layout>
    </EntryContextProvider>
  );
};
export default AboutPage;
